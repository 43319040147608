import { Component, ViewChild, ElementRef, Output, EventEmitter, Input, AfterViewInit, Renderer2, HostListener } from '@angular/core';
import { PostEditorComponent } from '../post-editor/post-editor.component';
import { NavigationService } from 'src/app/_services';

@Component({
    selector: 'comment-input',
    templateUrl: './comment-input.component.html',
    styleUrl: './comment-input.component.scss'
})
export class CommentInputComponent implements AfterViewInit {
    @ViewChild('commentInput') commentInput!: ElementRef
    @ViewChild('textarea') textarea!: ElementRef
    @ViewChild(PostEditorComponent) input!: PostEditorComponent

    @Input() inputFocus: boolean = true
    @Input() loading: boolean = false
    @Output() handleEnter: EventEmitter<string> = new EventEmitter<string>()

    inputIsFocused: boolean = false
    docHeight!: number
    currentScrollPosition: number = 0
    currentViewportHeight!: number
    initialLoad: boolean = true

    constructor(
        private renderer: Renderer2,
        private navService: NavigationService
    ) { }

    commentText = ''
    initialViewportHeight: number | undefined

    ngAfterViewInit(): void {
        this.docHeight = document.body.scrollHeight
        this.initialViewportHeight = window.visualViewport?.height

        window.visualViewport?.addEventListener('resize', () => {
            this.currentViewportHeight = window.visualViewport!.height

            if (this.initialLoad && this.inputIsFocused) {
                this.setInputStyles()
                this.initialLoad = false
                return
            }

            this.setUnsetInputStyles()
        })
    }

    getInputTopPosition(): number {
        return this.currentViewportHeight - this.commentInput.nativeElement.offsetHeight + this.currentScrollPosition
    }

    @HostListener('window:scroll')
    onScroll(): void {
        this.currentScrollPosition = window.scrollY
        if (this.inputIsFocused) {
            this.setUnsetInputStyles()
        }
    }

    setUnsetInputStyles(): void {
        const difference = this.initialViewportHeight! - this.currentViewportHeight

        setTimeout(() => {
            if ((this.currentViewportHeight >= this.initialViewportHeight! && difference > 150) || difference < 0) {
                this.resetInputStyles()
            } else {
                this.setInputStyles()
            }
        }, 800)
    }

    editorFocus(focusing: boolean): void {
        //this.inputIsFocused = focusing

        if (this.initialLoad) {
            return
        }
        this.navService.navigateToAppStore()
        return
        if (!this.inputIsFocused) {

            this.resetInputStyles()
            return
        }

        this.setInputStyles()
    }

    resetInputStyles(): void {
        this.renderer.removeStyle(this.commentInput.nativeElement, 'position')
        this.renderer.removeStyle(this.commentInput.nativeElement, 'top')
        this.renderer.removeStyle(this.commentInput.nativeElement, 'bottom')
    }

    setInputStyles(): void {
        if (!this.commentInput) {
            return
        }

        const top = this.getInputTopPosition()

        if (isNaN(top)) {
            return
        }

        this.renderer.setStyle(this.commentInput.nativeElement, 'position', 'absolute')
        this.renderer.setStyle(this.commentInput.nativeElement, 'top', `${top}px`)
        this.renderer.setStyle(this.commentInput.nativeElement, 'bottom', `unset`)
    }

    setText(postTxt: string): void {
        this.commentText = postTxt
        this.submit()
    }

    sendBtnClicked(): void {
        this.navService.navigateToAppStore()
        return
        this.input.post()
    }

    submit(): void {
        this.navService.navigateToAppStore()
        return
        if (!this.commentText) {
            return
        }

        this.handleEnter.emit(this.commentText)
        this.commentText = ''
    }
}